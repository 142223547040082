import { Component, OnInit } from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: "app-terms",
  templateUrl: "./terms.component.html",
  styleUrls: ["./terms.component.scss"]
})
export class TermsComponent implements OnInit {

  termsAndConditionsTitle: string;
  lang: string;
  useSpanish: boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.lang = "es";
    this.handleLanguage(this.lang);
    console.log("this router", this.router, this.activatedRoute);
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.lang = params.lang;
        console.log(this.lang);
        this.handleLanguage(this.lang);
      }
    );
  }

  handleLanguage(language: string): void {
    if (language === "en") {
      this.useSpanish = false;
      this.termsAndConditionsTitle = "Terms and Conditions";
    } else {
      this.useSpanish = true;
      this.termsAndConditionsTitle = "Términos y Condiciones";
    }
  }

}
