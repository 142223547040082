<div fxFlex class="login-area app_background" fxLayoutAlign="space-around center">
  <mat-card class="login-card mat-elevation-z5" ngClass.lt-md="card-small" >
    <img ngClass.lt-md="image-small" src="assets/images/kaLogo.png" class="image_size">
    <div fxLayoutAlign="space-around center">
      <h2>{{theTitle}}</h2>
    </div>
    <mat-card-content fxLayout="column">
      <p>{{theText}}</p>
    </mat-card-content>
    <mat-card-actions align="end">
      <button class="common-button" mat-raised-button color="primary" [disabled]="!btnEnabled" (click)="clickButton()">{{thanksAndRedirect | uppercase}}</button>
    </mat-card-actions>
  </mat-card>
</div>
