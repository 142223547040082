import { Component, OnInit } from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
  selector: "app-privacy",
  templateUrl: "./privacy.component.html",
  styleUrls: ["./privacy.component.scss"]
})
export class PrivacyComponent implements OnInit {

  privacyTitle: string;
  lang: string;
  useSpanish: boolean;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit(): void {
    this.lang = "es";
    const langParam = "lang"
    this.handleLanguage(this.lang);
    console.log("this router", this.router, this.activatedRoute);
    this.activatedRoute.queryParams.subscribe(
      params => {
        this.lang = params[langParam];
        console.log(this.lang);
        this.handleLanguage(this.lang);
      }
    );
  }

  handleLanguage(language: string): void {
    if (language === "en") {
      this.useSpanish = false;
      this.privacyTitle = "Privacy Policy";
    } else {
      this.useSpanish = true;
      this.privacyTitle = "Política de privacidad";
    }
  }

}
