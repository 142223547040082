<div fxFlex class="login-area app_background" fxLayoutAlign="space-around center">
  <mat-card class="login-card mat-elevation-z5" ngClass.lt-md="card-small">
    <form [formGroup]="forgotForm" (ngSubmit)="submitForgot(forgotForm.getRawValue())">
      <img ngClass.lt-md="image-small" src="assets/images/kaLogo.png" class="image_size">
      <div fxLayoutAlign="space-around center">
        <h3 class="card-title-h3">{{forgotTitle}}</h3>
      </div>
      <mat-card-content fxLayout="column">
        <mat-form-field appearance="fill" fxFlex class="FormFieldAll">
          <mat-label>{{paswordText}}</mat-label>
          <input matInput type="password" [formControl]="newPassword" required>
          <mat-error *ngIf="hasError('newPassword', 'required')">{{requieredPassword}}</mat-error>
          <mat-error *ngIf="hasError('newPassword', 'minlength')">{{shortPassword}}</mat-error>
          <mat-error *ngIf="hasError('newPassword', 'maxlength')">{{longPassword}}</mat-error>
        </mat-form-field>
        <mat-form-field appearance="fill" fxFlex class="FormFieldAll">
          <mat-label>{{paswordConfirmText}}</mat-label>
          <input matInput type="password" [formControl]="newPasswordConfirm" required>
          <mat-error *ngIf="hasError('newPasswordConfirm', 'required')">{{requieredPassword}}</mat-error>
          <mat-error *ngIf="hasError('newPasswordConfirm', 'minlength')">{{shortPassword}}</mat-error>
          <mat-error *ngIf="hasError('newPasswordConfirm', 'maxlength')">{{longPassword}}</mat-error>
          <mat-error *ngIf="hasError('newPasswordConfirm', 'equalvalue')">{{notMatchPassword}}</mat-error>
        </mat-form-field>
      </mat-card-content>
      <mat-card-actions align="end">
        <button mat-raised-button class="common-button" (click)="closeMe()" type="button">{{cancelButton | uppercase}}</button>
        <button mat-raised-button class="common-button" [disabled]="!forgotForm.valid" color="primary" type="submit">{{sendButton | uppercase}}</button>
      </mat-card-actions>
    </form>
  </mat-card>
</div>
