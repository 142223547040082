import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: "app-app-dialog",
  templateUrl: "./app-dialog.component.html",
  styleUrls: ["./app-dialog.component.scss"]
})
export class AppDialogComponent implements OnInit {

  displayTitle: string;
  displayText: string;
  displayButton: string;

  constructor(public dialogRef: MatDialogRef<AppDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit(): void {
    this.displayTitle = this.data.title;
    this.displayText = this.data.text;
    this.displayButton = this.data.button;
  }

  closeDialog(): void {
    this.dialogRef.close();
  }
}
