import {RouterModule, Routes} from "@angular/router";
import {TermsComponent} from "./terms/terms.component";
import {AppMainComponent} from "./app-main/app-main.component";
import {PrivacyComponent} from "./privacy/privacy.component";

const routes: Routes = [
  {path: "", component: TermsComponent},
  {path: "auth", component: AppMainComponent},
  {path: "terms", component: TermsComponent},
  {path: "privacy", component: PrivacyComponent}
];

export const AppRoutingProviders: any[] = [];

export const AppRoutes: any = RouterModule.forRoot(routes, { useHash: true });
