<div fxLayout="column">
  <mat-toolbar>
    <img src="https://firebasestorage.googleapis.com/v0/b/kostruaccess-c0a5c.appspot.com/o/public%2FkostruAccessLogo.png?alt=media&token=ddff6820-1ae4-4f0f-a75f-2a2303d4a9d0" width="96px"><span style="padding-left: 20px" ><h1>{{termsAndConditionsTitle}}</h1></span>
  </mat-toolbar>
  <div fxFlex fxFill style="padding: 25px" *ngIf="!useSpanish">
    <h2><strong>Terms and Conditions</strong></h2>
    <p>Welcome to KostruAccess!</p>

    <p>These terms and conditions outline the rules and regulations for the use of KostruAccess's Website, located at kooguen.com,
      "KostruAccess" on Play Store and the upcoming "KostruAccess" on App Store.</p>

    <p>By accessing this website and/or "KostruAccess" we assume you accept these terms and conditions. Do not continue to use KostruAccess
      if you do not agree to take all of the terms and conditions stated on this page or application.</p>

    <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all
      Agreements: "Client", "You" and "Your" refers to you, the person log on this website and compliant to the
      Company’s terms and conditions. "The Company", "Ourselves", "We", "Our" and "Us", refers to our Company. "Party",
      "Parties", or "Us", refers to both the Client and ourselves. All terms refer to the offer, acceptance and
      consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate
      manner for the express purpose of meeting the Client’s needs in respect of provision of the Company’s stated services,
      in accordance with and subject to, prevailing law of Netherlands. Any use of the above terminology or other words in the singular,
      plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>

    <h3><strong>Cookies</strong></h3>

    <p>We employ the use of cookies. By accessing KostruAccess and/or our apps, you agreed to use cookies in agreement with the KostruAccess's <a [routerLink]="['/privacy']">Privacy Policy</a>.</p>

    <p>Most interactive websites and applications use cookies to let us retrieve the user’s details for each visit and or interaction.
      Cookies are used by our website and apps to enable the functionality of certain areas to make it easier for people
      visiting our website. Some of our affiliate/advertising partners may also use cookies.</p>

    <h3><strong>License</strong></h3>

    <p>Unless otherwise stated, KostruAccess and/or its licensors own the intellectual property rights for all material on KostruAccess
      and/or their aplicaciones. All intellectual property rights are reserved.
      You may access this from KostruAccess for your own personal use subjected to restrictions set in these terms and conditions.</p>

    <p>You must not:</p>
    <ul>
      <li>Republish material from KostruAccess</li>
      <li>Sell, rent or sub-license material from KostruAccess</li>
      <li>Reproduce, duplicate or copy material from KostruAccess</li>
      <li>Redistribute content from KostruAccess</li>
    </ul>

    <p>This Agreement shall begin on the date hereof.</p>

    <p>Parts of our website and applications offer an opportunity for users to post and exchange opinions and information in certain areas of the website.
      KostruAccess does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of
      KostruAccess,its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions.
      To the extent permitted by applicable laws, KostruAccess shall not be liable for the Comments or for any liability,
      damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website</p>

    <p>KostruAccess reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.</p>

    <p>You warrant and represent that:</p>

    <ul>
      <li>You are entitled to post the Comments on our website and have all necessary licenses and consents to do so.</li>
      <li>The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;</li>
      <li>The Comments do not contain any defamatory, libelous, offensive, indecent or otherwise unlawful material which is an invasion of privacy</li>
      <li>The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.</li>
    </ul>

    <p>You hereby grant KostruAccess a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.</p>

    <h3><strong>Hyperlinking to our Content</strong></h3>

    <p>The following organizations may link to our Website and/or apps without prior written approval:</p>

    <ul>
      <li>Government agencies;</li>
      <li>Search engines;</li>
      <li>News organizations;</li>
      <li>Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and</li>
      <li>System wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.</li>
    </ul>

    <p>These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.</p>

    <p>We may consider and approve other link requests from the following types of organizations:</p>

    <ul>
      <li>commonly-known consumer and/or business information sources;</li>
      <li>dot.com community sites;</li>
      <li>associations or other groups representing charities;</li>
      <li>online directory distributors;</li>
      <li>internet portals;</li>
      <li>accounting, law and consulting firms; and</li>
      <li>educational institutions and trade associations.</li>
    </ul>

    <p>We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavorably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of KostruAccess; and (d) the link is in the context of general resource information.</p>

    <p>These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.</p>

    <p>If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to KostruAccess. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.</p>

    <p>Approved organizations may hyperlink to our Website as follows:</p>

    <ul>
      <li>By use of our corporate name; or</li>
      <li>By use of the uniform resource locator being linked to; or</li>
      <li>By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.</li>
    </ul>

    <p>No use of KostruAccess's logo or other artwork will be allowed for linking absent a trademark license agreement.</p>

    <h3><strong>iFrames</strong></h3>

    <p>Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.</p>

    <h3><strong>Content Liability</strong></h3>

    <p>We shall not be hold responsible for any content that appears on your Website. You agree to protect and defend us against all claims that is rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.</p>

    <h3><strong>Your Privacy</strong></h3>

    <p>Please read Privacy Policy</p>

    <h3><strong>Reservation of Rights</strong></h3>

    <p>We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amen these terms and conditions and it’s linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.</p>

    <h3><strong>Removal of links from our website and/or apps</strong></h3>

    <p>If you find any link on our Website that is offensive for any reason, you are free to contact and inform us any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.</p>

    <p>We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.</p>

    <h3><strong>Disclaimer</strong></h3>

    <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:</p>

    <ul>
      <li>limit or exclude our or your liability for death or personal injury;</li>
      <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
      <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
      <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
    </ul>

    <p>The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.</p>

    <p>As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.</p>
    <p>All the names and marks used in this terms are property of their respective owners with no exceptions!</p>
  </div>
  <div fxFlex fxFill style="padding: 25px" *ngIf="useSpanish">
    <h2><strong>Términos y Condiciones</strong></h2>

    <p>Bienvenido a KostruAccess!</p>

    <p>Estos términos y condiciones describen las reglas y regulaciones para el uso del sitio web de KostruAccess, ubicado en
      KostruAccess.kooguen.com, "KostruAccess" en Play Store y la próxima "KostruAccess" en App Store.</p>

    <p>Al acceder a este sitio web y / o "KostruAccess" asumimos que acepta estos términos y condiciones. No continúe usando KostruAccess
      si no acepta tomar todos los términos y condiciones establecidos en esta página o aplicación.</p>

    <p>La siguiente terminología se aplica a estos Términos y condiciones, Declaración de privacidad y Aviso de exención de
      responsabilidad y todos los Acuerdos: "Cliente", "Usted" y "Su" se refieren a usted, la persona inicia sesión en este sitio web
      y cumple con los términos y condiciones de la Compañía. "La Compañía", "Nosotros", "Nosotros", "Nuestro" y "Nosotros", se
      refiere a nuestra Compañía. "Parte", "Partes" o "Nosotros", se refiere tanto al Cliente como a nosotros mismos. Todos los
      términos se refieren a la oferta, aceptación y consideración del pago necesario para llevar a cabo el proceso de nuestra
      asistencia al Cliente de la manera más apropiada con el propósito expreso de satisfacer las necesidades del Cliente con
      respecto a la prestación de los servicios declarados de la Compañía, de acuerdo con y sujeto a la ley vigente en los Países Bajos.
      Cualquier uso de la terminología anterior u otras palabras en singular, plural,
      mayúsculas y / o él / ella o ellos, se consideran intercambiables y, por lo tanto, se refieren a la misma.</p>

    <h3><strong>Cookies</strong></h3>

    <p>Empleamos el uso de cookies. Al acceder a KostruAccess y / o nuestras aplicaciones, usted acordó usar cookies de acuerdo con la <a [routerLink]="['/privacy']">Política de Privacidad</a> de KostruAccess.</p>

    <p>La mayoría de los sitios web y aplicaciones interactivos usan cookies para permitirnos recuperar los detalles del
      usuario para cada visita o interacción. Nuestro sitio web y nuestras aplicaciones utilizan cookies para habilitar la
      funcionalidad de ciertas áreas y facilitar a las personas que visitan nuestro sitio web.
      Algunos de nuestros socios afiliados / publicitarios también pueden usar cookies.</p>

    <h3><strong>Licencia</strong></h3>

    <p>A menos que se indique lo contrario, KostruAccess y / o sus licenciantes poseen los derechos de propiedad intelectual de todo el material en KostruAccess
      y / o sus aplicaciones. Todos los derechos de propiedad intelectual están reservados. Puede acceder a esto desde KostruAccess
      para su uso personal sujeto a las restricciones establecidas en estos términos y condiciones.</p>

    <p>No debes:</p>
    <ul>
      <li>Volver a publicar material de KostruAccess</li>
      <li>Vender, alquilar o sublicenciar material de KostruAccess</li>
      <li>Reproducir, duplicar o copiar material de KostruAccess</li>
      <li>Redistribuir contenido desde KostruAccess</li>
    </ul>

    <p>Este Acuerdo comenzará en la fecha del mismo.</p>

    <p>Partes de nuestro sitio web y aplicaciones ofrecen una oportunidad para que los usuarios publiquen e intercambien
      opiniones e información en ciertas áreas del sitio web. KostruAccess no filtra, edita, publica o revisa los comentarios
      antes de su presencia en el sitio web. Los comentarios no reflejan los puntos de vista y opiniones de KostruAccess, sus
      agentes y / o afiliados. Los comentarios reflejan los puntos de vista y opiniones de la persona que publica sus puntos
      de vista y opiniones. En la medida permitida por las leyes aplicables, KostruAccess no será responsable de los Comentarios
      o de cualquier responsabilidad, daños o gastos causados y / o sufridos como resultado de cualquier uso y / o
      publicación y / o aparición de los Comentarios en este sitio web.</p>

    <p>KostruAccess se reserva el derecho de monitorear todos los Comentarios y eliminar cualquier Comentario que pueda considerarse
      inapropiado, ofensivo o que infrinja estos Términos y Condiciones.</p>

    <p>Usted garantiza y declara que:</p>

    <ul>
      <li>Tiene derecho a publicar los Comentarios en nuestro sitio web y tiene todas las licencias y consentimientos necesarios para hacerlo.</li>
      <li>Los Comentarios no invaden ningún derecho de propiedad intelectual, incluidos, entre otros, los derechos de autor, patentes o marcas comerciales de terceros.</li>
      <li>Los comentarios no contienen ningún material difamatorio, difamatorio, ofensivo, indecente o ilegal que sea una invasión de la privacidad.</li>
      <li>Los Comentarios no se utilizarán para solicitar o promover negocios o actividades comerciales personalizadas o presentes o actividades ilegales.</li>
    </ul>

    <p>Por la presente, otorga a KostruAccess una licencia no exclusiva para usar, reproducir, editar y autorizar a otros a usar, reproducir y editar cualquiera de sus Comentarios en cualquiera y en todas las formas, formatos o medios.</p>

    <h3><strong>Hipervínculos a nuestro contenido</strong></h3>

    <p>Las siguientes organizaciones pueden vincular a nuestro sitio web y/o sin aprobación previa por escrito:</p>

    <ul>
      <li>Agencias gubernamentales;</li>
      <li>Los motores de búsqueda;</li>
      <li>Organizaciones de noticias;</li>
      <li>Los distribuidores de directorios en línea pueden vincular a nuestro sitio web de la misma manera que hacen hipervínculos a los sitios web de otras empresas enumeradas; y</li>
      <li>Empresas acreditadas en todo el sistema, excepto solicitar organizaciones sin fines de lucro, centros comerciales
        de caridad y grupos de recaudación de fondos de caridad que no pueden hacer hipervínculos a nuestro sitio web y / o aplicaciones.</li>
    </ul>

    <p>Estas organizaciones pueden enlazar a nuestra página de inicio, a publicaciones u otra información del sitio web siempre que el enlace: (a) no sea de ninguna manera engañoso; (b) no implica falsamente patrocinio, respaldo o aprobación de la parte vinculante y sus productos y / o servicios; y (c) se ajusta dentro del contexto del sitio y / o aplicaciones de la parte vinculante.</p>

    <p>Podemos considerar y aprobar otras solicitudes de enlace de los siguientes tipos de organizaciones:</p>

    <ul>
      <li>fuentes de información de consumidores y / o negocios comúnmente conocidas;</li>
      <li>sitios de la comunidad dot.com;</li>
      <li>asociaciones u otros grupos que representan organizaciones benéficas;</li>
      <li>distribuidores de directorios en línea;</li>
      <li>portales de internet;</li>
      <li>empresas de contabilidad, derecho y consultoría; y</li>
      <li>instituciones educativas y asociaciones comerciales.</li>
    </ul>

    <p>Aprobaremos solicitudes de enlaces de estas organizaciones si decidimos que:
      (a) el enlace no nos haría mirar desfavorablemente a nosotros mismos ni a nuestros negocios acreditados;
      (b) la organización no tiene ningún registro negativo con nosotros;
      (c) el beneficio para nosotros de la visibilidad del hipervínculo compensa la ausencia de KostruAccess; y
      (d) el enlace está en el contexto de información general de recursos.</p>

    <p>Estas organizaciones pueden enlazar a nuestra página de inicio siempre que el enlace:
      (a) no sea de ninguna manera engañoso;
      (b) no implica falsamente patrocinio, respaldo o aprobación de la parte vinculante y sus productos o servicios; y
      (c) se ajusta dentro del contexto del sitio de la parte vinculante.</p>

    <p>Si usted es una de las organizaciones enumeradas en el párrafo 2 anterior y está interesado en vincularse a nuestro
      sitio web, debe informarnos enviando un correo electrónico a KostruAccess. Incluya su nombre, el nombre de su organización,
      la información de contacto, así como la URL de su sitio, una lista de todas las URL desde las que desea vincular a
      nuestro sitio web y una lista de las URL de nuestro sitio a las que desea enlace. Espere 2-3 semanas para una respuesta.</p>

    <p>Las organizaciones aprobadas pueden hacer hipervínculos a nuestro sitio web y / o aplicaciones de la siguiente manera:</p>

    <ul>
      <li>Mediante el uso de nuestro nombre corporativo; o</li>
      <li>Mediante el uso del localizador uniforme de recursos al que se vincula; o</li>
      <li>Mediante el uso de cualquier otra descripción de nuestro sitio web al que se vincule, eso tiene sentido dentro del contexto y el formato del contenido en el sitio de la parte vinculante.</li>
    </ul>

    <p>No se permitirá el uso del logotipo de KostruAccess u otra obra de arte para vincular en ausencia de un acuerdo de licencia de marca registrada.</p>

    <h3><strong>iFrames</strong></h3>

    <p>Sin aprobación previa y permiso por escrito, no puede crear marcos alrededor de nuestras páginas web que alteren
      de ninguna manera la presentación visual o la apariencia de nuestro sitio web.</p>

    <h3><strong>Responsabilidad de contenido</strong></h3>

    <p>No seremos responsables del contenido que aparezca en su sitio web. Usted acepta protegernos y defendernos contra
      todas las reclamaciones que están aumentando en su sitio web. No debe aparecer ningún enlace en ningún sitio web
      que pueda interpretarse como difamatorio, obsceno o criminal, o que infrinja, de otra manera viole o defienda la
      infracción u otra violación de los derechos de terceros.</p>

    <h3><strong>Tu privacidad</strong></h3>

    <p>Por favor lea la política de privacidad</p>

    <h3><strong>Reserva de derechos</strong></h3>

    <p>Nos reservamos el derecho de solicitar que elimine todos los enlaces o cualquier enlace particular a nuestro sitio web.
      Usted aprueba eliminar inmediatamente todos los enlaces a nuestro sitio web a pedido. También nos reservamos el derecho de
      modificar estos términos y condiciones y su política de vinculación en cualquier momento.
      Al vincular continuamente a nuestro sitio web, usted acepta estar obligado y seguir estos términos y condiciones de vinculación.</p>

    <h3><strong>Eliminación de enlaces de nuestro sitio web</strong></h3>

    <p>Si encuentra algún enlace en nuestro sitio web que sea ofensivo por cualquier motivo, puede contactarnos e informarnos
      en cualquier momento. Consideraremos solicitudes para eliminar enlaces, pero no estamos obligados a responderle directamente.</p>

    <p>No nos aseguramos de que la información en este sitio web sea correcta, no garantizamos su integridad o precisión;
      ni prometemos garantizar que el sitio web permanezca disponible o que el material en el sitio web se mantenga actualizado.</p>

    <h3><strong>Descargo de responsabilidad</strong></h3>

    <p>En la medida máxima permitida por la ley aplicable, excluimos todas las representaciones, garantías y condiciones
      relacionadas con nuestro sitio web y el uso de este sitio web. Nada en este descargo de responsabilidad:</p>

    <ul>
      <li>limitar o excluir nuestra responsabilidad o la suya por muerte o lesiones personales;</li>
      <li>limitar o excluir nuestra responsabilidad o la suya por fraude o tergiversación fraudulenta;</li>
      <li>limitar cualquiera de nuestras responsabilidades o las suyas de cualquier manera que no esté permitida por la ley aplicable; o</li>
      <li>excluir cualquiera de nuestras responsabilidades o las suyas que no puedan excluirse según la ley aplicable.</li>
    </ul>

    <p>Las limitaciones y prohibiciones de responsabilidad establecidas en esta Sección y en otras partes de este descargo de responsabilidad:
      (a) están sujetas al párrafo anterior; y
      (b) regirá todas las responsabilidades derivadas del descargo de responsabilidad, incluidas las obligaciones derivadas de contratos, daños y por incumplimiento
      de obligaciones legales.</p>

    <p>Mientras el sitio web y la información y servicios en el sitio web se proporcionen de forma gratuita, no seremos
      responsables de ninguna pérdida o daño de ninguna naturaleza.</p>
    <p>Todos los nombres y marcas usadas en estos terminos y condiciones son propiedad de sus respectivos dueños sin excepción alguna!</p>
  </div>
</div>
