import {FormGroup, ValidatorFn} from "@angular/forms";

export function equalValueValidator(targetKey: string, toMatchKey: string): ValidatorFn {
  return (group: FormGroup): { [key: string]: any } => {
    const target = group.controls[targetKey];
    const toMatch = group.controls[toMatchKey];
    if (target.dirty && toMatch.dirty) {
      const isMatch = target.value === toMatch.value;
      // set equal value error on dirty controls
      if (!isMatch && target.valid && toMatch.valid) {
        toMatch.setErrors({equalvalue: targetKey});
        const message = targetKey + " != " + toMatchKey;
        console.log("Error", toMatch.errors);
        return {equalvalue: message};
      }
      if (isMatch && toMatch.hasError("equalvalue")) {
        toMatch.setErrors(null);
      }
    }
    return null;
  };
}
