import { Component, OnInit } from "@angular/core";
import {from, Subject} from "rxjs";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {AngularFireAuth} from "@angular/fire/auth";
import {AppDialogComponent} from "../app-dialog/app-dialog.component";

@Component({
  selector: "app-app-main",
  templateUrl: "./app-main.component.html",
  styleUrls: ["./app-main.component.scss"]
})
export class AppMainComponent implements OnInit {

  title = "AngularAuthTokk";
  mode: string;
  ac: string;
  cu: string;
  lg: string;
  showPasswordReset: boolean;
  showRecoverEmail: boolean;
  showVerifyEmail: boolean;
  accountEmail: string;
  restoredEmail: string;
  restoredEmailText: string;
  enableRecoverEmailBtn: boolean;
  changeTextSubject: Subject<string> = new Subject<string>();
  changeBtnSubject: Subject<boolean> = new Subject<boolean>();
  changeTitleSubject: Subject<string> = new Subject<string>();

  constructor(private activatedRoute: ActivatedRoute, public router: Router, public auth: AngularFireAuth, public cpDialog: MatDialog) {
  }

  ngOnInit(): void {
    console.log("Init Main");
    this.showPasswordReset = false;
    this.showRecoverEmail = false;
    this.showVerifyEmail = false;
    this.restoredEmailText = "Procesando...";
    this.enableRecoverEmailBtn = false;
    this.activatedRoute.queryParams.subscribe(
      data => {
        let theMode = "";
        let theActionCode = "";
        let theContinueUrl = "";
        let theLang = "";
        theMode = data.mode;
        theActionCode = data.oobcode;
        theContinueUrl = data.continueUrl;
        theLang = data.lang;
        this.mode =  data.mode;
        this.ac = data.oobCode;
        this.cu = data.continueUrl;
        this.lg = data.lang;
        this.handleAction();
      }
    );

  }

  handleAction(): void {
    switch (this.mode) {
      case "resetPassword":
        // Display reset password handler and UI.
        this.showPasswordReset = true;
        this.handleResetPassword();
        break;
      case "recoverEmail":
        // Display email recovery handler and UI.
        this.showRecoverEmail = true;
        this.handleRecoverEmail();
        break;
      case "verifyEmail":
        // Display email verification handler and UI.
        this.showRecoverEmail = true;
        this.handleVerifyEmail();
        break;
      default:
        console.error("None of the Above");
        this.showRecoverEmail = true;
        this.handleVerifyEmail();
      // Error: invalid mode.
    }
  }

  handleResetPassword(): void {
    from(this.auth.verifyPasswordResetCode(this.ac)).subscribe(
      email => {
        this.accountEmail = email;
      }, error => {
        console.error("Error", error);
      }
    );
  }

  getNewPassword(newPassword: string): void {
    from(this.auth.confirmPasswordReset(this.ac, newPassword)).subscribe(
      next => {
        const cpDialogRef = this.cpDialog.open(AppDialogComponent, {
          width: "350px",
          disableClose: true,
          data: {title: "Muy bien!", text: "La contraseña se cambió exitoramente", button: "Aceptar y Cerrar"}
        });
        cpDialogRef.afterClosed().subscribe(
          next2 => window.close()
        );
      }, error => {
        const cpDialogRef = this.cpDialog.open(AppDialogComponent, {
          width: "350px",
          disableClose: true,
          data: {title: "Algo esta mal!", text: "Por favor vuelva a intentarlo", button: "Aceptar y Cerrar"}
        });
        cpDialogRef.afterClosed().subscribe(
          next2 => window.close()
        );
      }
    );
  }

  handleRecoverEmail(): void {
    this.restoredEmail = null;
    console.log("Code", this.ac);
    const data = "data";
    const email = "email";
    from(this.auth.checkActionCode(this.ac)).subscribe(
      next => {
        this.restoredEmail = next[data][email];
        from(this.auth.applyActionCode(this.ac)).subscribe(
          next2 => {
            this.restoredEmailText = "Se restableció correctamente su correo electrónico, adicionalmente se envió un" +
              " mensaje para que cambie su contraseña, si o considera necesario, de lo contrario, por favor omita ese mensaje!";
            this.changeTextSubject.next("Se restableció correctamente su correo electrónico, adicionalmente se envió un" +
              " mensaje para que cambie su contraseña, si o considera necesario, de lo contrario, por favor omita ese mensaje!");
            this.changeBtnSubject.next(true);
            this.changeTitleSubject.next("Recuperación de correo electrónico");
          }, error => {
            this.restoredEmailText = "Algo salió mal!, por favor intentelo nuevamente!";
            this.changeTextSubject.next("Algo salió mal!, por favor intentelo nuevamente!");
            this.changeBtnSubject.next(true);
            this.changeTitleSubject.next("Recuperación de correo electrónico");
          }
        );
      }, error => {
        console.log(error);
        this.restoredEmailText = "Algo salió mal!, por favor intentelo nuevamente!";
        this.changeTextSubject.next("Algo salió mal!, por favor intentelo nuevamente!");
        this.changeBtnSubject.next(true);
        this.changeTitleSubject.next("Recuperación de correo electrónico");
      }
    );
  }

  handleVerifyEmail(): void {
    from(this.auth.applyActionCode(this.ac)).subscribe(
      next => {
        this.restoredEmailText = "Se verificó correctamente su correo electrónico! Ahora puede volver a iniciar sesión!";
        this.changeTextSubject.next("Se verificó correctamente su correo electrónico! Ahora puede volver a iniciar sesión!");
        this.changeBtnSubject.next(true);
        this.changeTitleSubject.next("Verificación de correo electrónico");
      }, error => {
        this.restoredEmailText = "Algo salió mal!, por favor intentelo nuevamente!";
        this.changeTextSubject.next("Algo salió mal!, por favor intentelo nuevamente!");
        this.changeBtnSubject.next(true);
        this.changeTitleSubject.next("Verificación de correo electrónico");
      }
    );
  }

}
