import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";

import { AppComponent } from "./app.component";
import { PrivacyComponent } from "./privacy/privacy.component";
import { TermsComponent } from "./terms/terms.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import {AppRoutes, AppRoutingProviders} from "./app.router";
import {CoreModule} from "./core/core.module";
import { EmailRecoverComponent } from "./email-recover/email-recover.component";
import { PasswordResetComponent } from "./password-reset/password-reset.component";
import { AppDialogComponent } from "./app-dialog/app-dialog.component";
import { AppMainComponent } from "./app-main/app-main.component";

@NgModule({
  declarations: [
    AppComponent,
    PrivacyComponent,
    TermsComponent,
    EmailRecoverComponent,
    PasswordResetComponent,
    AppDialogComponent,
    AppMainComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutes,
    CoreModule,
  ],
  providers: [AppRoutingProviders],
  bootstrap: [AppComponent]
})
export class AppModule { }
