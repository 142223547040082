<div fxLayout="column">
  <mat-toolbar>
    <img src="https://firebasestorage.googleapis.com/v0/b/kostruaccess-c0a5c.appspot.com/o/public%2FkostruAccessLogo.png?alt=media&token=ddff6820-1ae4-4f0f-a75f-2a2303d4a9d0" width="96px"><span style="padding-left: 20px" ><h1>{{privacyTitle}}</h1></span>
  </mat-toolbar>
  <div fxFlex fxFill style="padding: 25px" *ngIf="!useSpanish">
    <h2>Privacy Policy</h2> <p>
    Kooguen SAS built the KostruAccess app as
    a Free app over a paid service. This SERVICE is provided by
    Kooguen SAS at a subscription cost and is intended for
    use as is.
  </p> <p>
    This page is used to inform visitors regarding
    my policies with the collection, use, and
    disclosure of Personal Information if anyone decided to use
    our Service.
  </p> <p>
    If you choose to use our Service, then you agree
    to the collection and use of information in relation to this
    policy. The Personal Information that we collect is
    used for providing and improving the Service.
    We will not use or share your
    information with anyone except as described in this Privacy
    Policy.
  </p> <p>
    The terms used in this Privacy Policy have the same meanings
    as in our <a [routerLink]="['/terms']">Terms and Conditions</a>, which is accessible at
    KostruAccess unless otherwise defined in this Privacy
    Policy.
  </p> <h3>Information Collection and Use</h3> <p>
    For a better experience, while using our Service,
    We may require you to provide us with certain
    personally identifiable information, including but not limited to email, full name, phone number, other willingly shared information. The
    information that I request will be
    retained on your device and is not collected by me in any way.
  </p> <p>
    The app and webapp does use third party services that may collect
    information used to identify you.
  </p> <div><p>
    Link to privacy policy of third party service providers
    used by the app
  </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank">Google Play Services</a></li><li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank">AdMob</a></li><li><a href="https://firebase.google.com/policies/analytics" target="_blank">Google Analytics for Firebase</a></li><li><a href="https://firebase.google.com/support/privacy/" target="_blank">Firebase Crashlytics</a></li><!----><li><a href="https://policies.google.com/privacy" target="_blank">Fabric</a></li><!----><!----><!----><!----><!----><!----><!----><!----><!----></ul></div>
    <h3>Log Data</h3> <p>
    We want to inform you that whenever
    you use our Service, in a case of an error in the
    app we collect data and information (through third
    party products) on your phone or web application called Log Data. This Log Data
    may include information such as your device Internet
    Protocol (“IP”) address, device name, operating system
    version, the configuration of the app when utilizing
    my Service, the time and date of your use of the
    Service, and other statistics.
  </p> <h3>Cookies</h3> <p>
    Cookies are files with a small amount of data that are
    commonly used as anonymous unique identifiers. These are
    sent to your browser from the websites that you visit and
    are stored on your device's internal memory.
  </p> <p>
    This Service does not use these “cookies” explicitly.
    However, the app may use third party code and libraries that
    use “cookies” to collect information and improve their
    services. You have the option to either accept or refuse
    these cookies and know when a cookie is being sent to your
    device. If you choose to refuse our cookies, you may not be
    able to use some portions of this Service.
  </p> <h3>Service Providers</h3> <p>
    We may employ third-party companies
    and individuals due to the following reasons:
  </p> <ul><li>To facilitate our Service;</li> <li>To provide the Service on our behalf;</li> <li>To perform Service-related services; or</li> <li>To assist us in analyzing how our Service is used.</li></ul> <p>
    We want to inform users of this
    Service that these third parties have access to your
    Personal Information. The reason is to perform the tasks
    assigned to them on our behalf. However, they are obligated
    not to disclose or use the information for any other
    purpose.
  </p> <h3>Security</h3> <p>
    We value your trust in providing us
    your Personal Information, thus we are striving to use
    commercially acceptable means of protecting it. But remember
    that no method of transmission over the internet, or method
    of electronic storage is 100% secure and reliable, and
    We cannot guarantee its absolute security.
  </p> <h3>Links to Other Sites</h3> <p>
    This Service may contain links to other sites. If you click
    on a third-party link, you will be directed to that site.
    Note that these external sites are not operated by
    us. Therefore, We strongly advise you to
    review the Privacy Policy of these websites.
    We have no control over and assume no
    responsibility for the content, privacy policies, or
    practices of any third-party sites or services.
  </p> <h3>Children’s Privacy</h3> <p>
    These Services do not address anyone under the age of 13.
    We do not knowingly collect personally
    identifiable information from children under 13. In the case
    We discover that a child under 13 has provided
    me with personal information,
    We immediately delete this from our servers. If you
    are a parent or guardian and you are aware that your child
    has provided us with personal information, please contact
    me so that We will be able to do
    necessary actions.
  </p> <h3>Changes to This Privacy Policy</h3> <p>
    We may update our Privacy Policy from
    time to time. Thus, you are advised to review this page
    periodically for any changes. We will
    notify you of any changes by posting the new Privacy Policy
    on this page. These changes are effective immediately after
    they are posted on this page.
  </p> <h3>Contact Us</h3> <p>
    If you have any questions or suggestions about
    our Privacy Policy, do not hesitate to contact
    us at <a href="mailto:info@kooguen.com" target="_top">info@kooguen.com</a>.
  </p>
  </div>
  <div fxFlex fxFill style="padding: 25px; scroll-behavior: auto" *ngIf="useSpanish">
    <h2>Política de privacidad</h2> <p>
    Kooguen SAS creó la aplicación KostruAccess como una aplicación gratuita sobre un servicio pago.
    Este SERVICIO es provisto por Kooguen SAS bajo modelos de subscripción y está destinado para su uso tal cual.
  </p> <p>
    Esta página se utiliza para informar a los visitantes sobre mis políticas con respecto a la recopilación,
    uso y divulgación de información personal si alguien decide utilizar mi servicio.
  </p> <p>
    Si elige usar nuestro Servicio, entonces acepta la recopilación y el uso de información en relación con esta política.
    La información personal que recopilamos se utiliza para proporcionar y mejorar el Servicio.
    No utilizaré ni compartiré su información con nadie, excepto como se describe en esta Política de privacidad.

  </p> <p>
    Los términos utilizados en esta Política de privacidad tienen el mismo significado que en nuestros <a [routerLink]="['/terms']">Términos y
    condiciones</a>, a los que se puede acceder en KostruAccess a menos que se defina lo contrario en esta Política de privacidad.
  </p> <h3>Recolección de información y uso</h3> <p>
    Para una mejor experiencia, al usar nuestro Servicio, puedo solicitarle que nos proporcione cierta información de
    identificación personal, que incluye, entre otros, correo electrónico, nombre completo, número de teléfono y otra información voluntariamente compartida.
    La información que solicito se conservará en su dispositivo y no la recopilaré de ninguna manera.
  </p> <p>
    La aplicación y el sitio web utiliza servicios de terceros
    que pueden recopilar información utilizada para identificarlo.
  </p> <div><p>
    Enlace a la política de privacidad de los proveedores de servicios de terceros utilizados por la aplicación
  </p> <ul><li><a href="https://www.google.com/policies/privacy/" target="_blank">Servicios de Google Play</a></li>
    <li><a href="https://support.google.com/admob/answer/6128543?hl=en" target="_blank">AdMob</a></li>
    <li><a href="https://firebase.google.com/policies/analytics" target="_blank">Google Analytics para Firebase</a></li>
    <li><a href="https://firebase.google.com/support/privacy/" target="_blank">Firebase Crashlytics</a></li>
    <li><a href="https://policies.google.com/privacy" target="_blank">Fabric</a></li></ul></div>
    <h3>Registro de Datos</h3> <p>
    Queremos informarle que cada vez que utiliza nuestro Servicio, en caso de un error en la aplicación,
    recopilo datos e información (a través de productos de terceros) en su teléfono, llamados Datos de registro al igual que en la aplicacion web.
    Estos datos de registro pueden incluir información como la dirección del Protocolo de Internet ("IP") de su dispositivo,
    el nombre del dispositivo, la versión del sistema operativo, la configuración de la aplicación al utilizar mi Servicio,
    la hora y la fecha de uso del Servicio y otras estadísticas .
  </p> <h3>Cookies</h3> <p>
    Las cookies son archivos con una pequeña cantidad de datos que se usan comúnmente como identificadores únicos anónimos.
    Estos se envían a su navegador desde los sitios web que visita y se almacenan en la memoria interna de su dispositivo.
  </p> <p>
    Este servicio no utiliza estas "cookies" explícitamente.
    Sin embargo, la aplicación puede usar código de terceros y bibliotecas que usan "cookies" para recopilar información y mejorar sus servicios.
    Tiene la opción de aceptar o rechazar estas cookies y saber cuándo se envía una cookie a su dispositivo.
    Si elige rechazar nuestras cookies, es posible que no pueda usar algunas partes de este Servicio.
  </p> <h3>Proveedores de servicio</h3> <p>
    Podemos emplear a empresas e individuos de terceros debido a las siguientes razones:
  </p> <ul><li>Para facilitar nuestro servicio;</li>
    <li>Para proporcionar el Servicio en nuestro nombre;</li>
    <li>Para realizar servicios relacionados con el Servicio; o</li>
    <li>Para ayudarnos a analizar cómo se utiliza nuestro Servicio.</li></ul> <p>
    Deseamos informar a los usuarios de este Servicio que estos terceros tienen acceso a su Información personal.
    El motivo es realizar las tareas que se les asignan en nuestro nombre.
    Sin embargo, están obligados a no divulgar ni utilizar la información para ningún otro propósito.
  </p> <h3>Seguridad</h3> <p>
    Valoramos su confianza en proporcionarnos su información personal,
    por lo tanto, nos esforzamos por utilizar medios comercialmente aceptables para protegerla.
    Pero recuerde que ningún método de transmisión a través de Internet o método de almacenamiento electrónico es 100%
    seguro y confiable, y no podemos garantizar su seguridad absoluta.
  </p> <h3>Enlaces a otros sitios</h3> <p>
    Este servicio puede contener enlaces a otros sitios. Si hace click en un enlace de un tercero,
    será dirigido a ese sitio. Tenga en cuenta que estos sitios externos no son operados por nosotros.
    Por lo tanto, le recomendamos que revise la Política de privacidad de estos sitios web.
    No tenemos control ni asumimos ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de sitios o servicios de terceros
  </p> <h3>Privacidad de los niños</h3> <p>
    Estos Servicios no se dirigen a ninguna persona menor de 13 años.
    No recopilamos intencionalmente información de identificación personal de niños menores de 13 años.
    En el caso de que se descubra que un niño menor de 13 años ha proporcionado información personal,
    la borramos inmediatamente de nuestros servidores.
    Si usted es un padre o tutor y sabe que su hijo nos ha brindado información personal, comuníquese con nosotros para que podamos realizar las acciones necesarias.
  </p> <h3>Cambios a esta política de privacidad</h3> <p>
    Podemos actualizar nuestra Política de privacidad de vez en cuando.
    Por lo tanto, se recomienda que revise esta página periódicamente en busca de cambios.
    Le notificaremos cualquier cambio publicando la nueva Política de privacidad en esta página.
    Estos cambios son efectivos inmediatamente después de su publicación en esta página.
  </p> <h3>Contáctenos</h3> <p>
    Si tiene alguna pregunta o sugerencia sobre nuestra Política de privacidad, no dude en contactarnos en <a href="mailto:info@kooguen.com" target="_top">info@kooguen.com</a>.
  </p>
    <p>Todos los nombres y marcas usadas en estos terminos y condiciones son propiedad de sus respectivos dueños sin excepción alguna!</p>
  </div>
</div>
