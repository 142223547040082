import {Component, OnInit, Output, EventEmitter} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {equalValueValidator} from "../shared/equalValueValidator";

@Component({
  selector: "app-password-reset",
  templateUrl: "./password-reset.component.html",
  styleUrls: ["./password-reset.component.scss"]
})
export class PasswordResetComponent implements OnInit {

  forgotForm: FormGroup;
  newPassword: FormControl;
  newPasswordConfirm: FormControl;
  forgotTitle = "Olvidó su contraseña?";
  paswordText = "Escriba su nueva contraseña";
  paswordConfirmText = "confirme su nueva contraseña";
  cancelButton = "Cancelar";
  subTitle = "Gestión Y Recuperación";
  sendButton = "Enviar";
  requieredPassword = "La contraseña es obligatoria";
  shortPassword = "La contraseña es demasiado corta";
  longPassword = "La contraseña es demasiado Larga";
  notMatchPassword: "la contraseña no coincide";
  @Output() newPasswordChanged = new EventEmitter<string>();

  constructor() { }

  ngOnInit(): void {
    this.newPassword = new FormControl("", [Validators.required, Validators.minLength(8),
      Validators.maxLength(20)]);
    this.newPasswordConfirm = new FormControl("", [Validators.required, Validators.minLength(8),
      Validators.maxLength(20)]);
    this.forgotForm = new FormGroup(
      {
        newPassword: this.newPassword,
        newPasswordConfirm: this.newPasswordConfirm
      }, equalValueValidator("newPassword", "newPasswordConfirm")
    );
  }

  /*
  showNoEmailDialog(kind: NotFoundKindEnum) {
    const dialogData: NotFoundEmailDialogData = {
      kind: kind,
      cancel: "",
      confirm: "Aceptar"
    };
    const configDialog: MatDialogConfig = new MatDialogConfig<any>();
    configDialog.data = dialogData;
    configDialog.width = "300px";
    configDialog.disableClose = true;
    const dialogRef = this.dialog.open(ForgotAuthDialogComponent, configDialog);
    dialogRef.afterClosed().subscribe(
      next =>
        console.log("Closed")
    );
  }
   */

  closeMe(): void {
    window.close();
  }

  hasError(controlName: string, errorName: string): boolean {
    return this.forgotForm.controls[controlName].hasError(errorName);
  }

  submitForgot(value: any): void {
    console.log("Emiting New password", value.newPassword);
    this.newPasswordChanged.emit(value.newPassword);
  }

}
