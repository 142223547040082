import {Component, Input, OnDestroy, OnInit} from "@angular/core";
import {Observable, Subscription} from "rxjs";
import {Router} from "@angular/router";

@Component({
  selector: "app-email-recover",
  templateUrl: "./email-recover.component.html",
  styleUrls: ["./email-recover.component.scss"]
})
export class EmailRecoverComponent implements OnInit, OnDestroy {

  recoveryTitle = "Recuperación de correo electrónico";
  thanksAndRedirect = "Gracias, Regresar";
  @Input() buttonEnabled = false;
  @Input() textRecovery = "";
  @Input() textOfTitle = "";
  @Input() textChange = new Observable<string>();
  @Input() btnChange = new Observable<boolean>();
  @Input() titleChange = new Observable<string>();
  subTitle = "Gestión Y Recuperación";
  theText = "";
  btnEnabled = false;
  theTitle = "";
  textSubscription: Subscription;
  enableBtnSubscription: Subscription;
  titleSubscription: Subscription;

  constructor(private router: Router) { }

  ngOnInit(): void {
    this.textSubscription = this.textChange.subscribe(
      next => {
        console.log("Nexting", next);
        this.theText = next;
      }
    );
    this.enableBtnSubscription = this.btnChange.subscribe(
      next => {
        console.log("Nexting", next);
        this.btnEnabled = next;
      }
    );
    this.titleSubscription = this.titleChange.subscribe(
      next => {
        console.log("Nexting", next);
        this.theTitle = next;
      }
    );
  }

  ngOnDestroy(): void {
    this.textSubscription.unsubscribe();
    this.enableBtnSubscription.unsubscribe();
    this.titleSubscription.unsubscribe();
  }

  clickButton(): void {
    this.router.navigate(["https://auth.kostruaccess.kooguen.com/links/verify"]);
  }

}
